<script setup lang="ts">
import { type HTMLAttributes, computed, ref } from 'vue'
import {
  ScrollAreaCorner,
  ScrollAreaRoot,
  type ScrollAreaRootProps,
  ScrollAreaViewport,
} from 'radix-vue'
import { useScroll } from '@vueuse/core'
import ScrollBar from './ScrollBar.vue'
import { cn } from '@/lib/utils'

const props = defineProps<ScrollAreaRootProps & { class?: HTMLAttributes['class'] }>()

// Create ref for the viewport element
const viewportRef = ref<HTMLElement | null>(null)

// Use the useScroll composable with proper type checking
const scroll = computed(() => {
  if (!viewportRef.value) return { x: 0, y: 0, isScrolling: false, arrivedState: { left: true, right: true, top: true, bottom: true } }
  return useScroll(viewportRef, {
    throttle: 10,
    behavior: 'smooth'
  })
})

// Expose scroll information via defineExpose with safe access
defineExpose({
  scroll: computed(() => ({
    x: scroll.value.x,
    y: scroll.value.y,
    isScrolling: scroll.value.isScrolling,
    arrivedState: scroll.value.arrivedState
  }))
})

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props
  return delegated
})
</script>

<template>
  <ScrollAreaRoot v-bind="delegatedProps" :class="cn('relative overflow-hidden', props.class)">
    <ScrollAreaViewport 
      ref="viewportRef"
      class="h-full w-full rounded-[inherit]"
    >
      <slot />
    </ScrollAreaViewport>
    <ScrollBar />
    <ScrollAreaCorner />
  </ScrollAreaRoot>
</template>
